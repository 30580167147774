import getBlogMenuItem from './getBlogMenuItem';

const getPostFullUrl = (postUrl, menu, isBackup) => {
  if (isBackup) return null;

  const blogMenuItem = getBlogMenuItem(menu);

  return blogMenuItem && typeof postUrl === 'string' ? `/${blogMenuItem.tag}${postUrl}` : '#';
};

export default getPostFullUrl;
